import React from "react";
import styled from "styled-components";
import RegisterContent from "./RegisterContent";
import { useLocation } from "react-router-dom";

const Register = () => {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    const tokenQuery = '?token=' + query.get('token');
    const url = "https://api.prod.kagu-rental.com/api/verify" + tokenQuery;

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    console.log(url);
    let res = fetch(url, requestOptions);

    return <Container>
        <Wrapper>
            <RegisterContent />
        </Wrapper>
    </Container>
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Wrapper = styled.div`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
`;

export default Register;