import React, { useState } from "react";
import styled from "styled-components";
import logo from "../assets/application-logo.png";
import Input from "./Input";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const [state, setState] = useState({
    email: '',
    password: '',
    confirm_password: '',
    password_err: '',
    confirm_password_err: '',
    isSubmitted: false,
    hasSendErr: false,
  });

  const updateEmail = (ev) => setState({
    ...state,
    email: ev.target.value
  })

  const updatePassword = (ev) => setState({
    ...state,
    password: ev.target.value
  });

  const updateConfirmPassword = (ev) => {
    setState({
      ...state,
      confirm_password: ev.target.value
    })
  };

  const sendRequest = async () => {
    let tokenQuery = '?token=' + query.get('token');
    let emailQuery = '&email=' + state.email;
    const url = "https://api.prod.kagu-rental.com/api/reset_password" + tokenQuery + emailQuery;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password: state.password })
    };

    try {
      let res = await fetch(url, requestOptions);
      console.log(res.status);
      console.log(res.status);
      return res.status === 204;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setState({ ...state, password_err: '', confirm_password_err: '' });
    if (state.password.length < 6) {
      setState({ ...state, password_err: '6文字以上入力してください' });
      return false;
    }
    if (state.password !== state.confirm_password) {
      setState({ ...state, confirm_password_err: 'パスワードと一致しません' });
      return false;
    }

    let isSuccess = await sendRequest();
    console.log(`isSuccess: ${isSuccess}`);
    if (isSuccess) {
      setState({ ...state, isSubmitted: true });

    } else {
      setState({ ...state, isSubmitted: true, hasSendErr: true });

    }

    return false;
  }

  return (
    <Container>
      <LogoWrapper>
        <ImageWrapper>
          <img src={logo} alt="" />
        </ImageWrapper>

        <h3>パスワード再設定</h3>
        <h4>Reset password</h4>
      </LogoWrapper>
      {
        state.isSubmitted
          ? state.hasSendErr
            ? <React.Fragment>
              <h3 className='hasSendErr'>パスワードを再設定できませんでした。</h3>
              <h4>もう一度スマートフォンのアプリからやり直してください。それでも完了できない場合はお手数ですが、運営までお問い合わせください。</h4>
            </React.Fragment>
            : <React.Fragment>
              <h3 className='hasBeenReset'>パスワードを再設定しました。</h3>
              <h4>Your password has been reset.</h4>
            </React.Fragment>
          : <Form onSubmit={handleSubmit}>
            <Input type="email" placeholder="メールアドレス Email" onChange={updateEmail} />
            <Input type="password" placeholder="パスワード Password" onChange={updatePassword} />
            {state.password_err.length > 1 ? <ErrStr>{state.password_err}</ErrStr> : <></>}
            <Input type="password" placeholder="確認用パスワード Confrim Password" onChange={updateConfirmPassword} />
            {state.confirm_password_err.length > 1 ? <ErrStr>{state.confirm_password_err}</ErrStr> :
              <></>}
            <button>再登録 Submit</button>
          </Form>
      }
    </Container>
  );
};

const ErrStr = styled.div`
  color: #fe2f75;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: #666666;
    /* margin-bottom: 1rem; */
  }

  button {
    width: 75%;
    max-width: 280px;
    min-width: 250px;
    height: 40px;
    border: none;
    margin: 1rem 0;
    box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background-color: #70edb9;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in;

    &:hover {
      transform: translateY(-3px);
    }
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 30px;
`;

const LogoWrapper = styled.div`
  img {
    max-width: 250px;
  }

  h3 {
    color: #ff8d8d;
    text-align: center;
    font-size: 22px;
  }

  h4 {
    color: #ff8d8d;
    text-align: center;
    font-size: 18px;
  }

  span {
    color: #5dc399;
    font-weight: 300;
    font-size: 18px;
  }
`;

const Container = styled.div`
  width: 100vw;
  max-width: 400px;
  backdrop-filter: blur(35px);
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  align-items: center;
  padding: 0 2rem;

  .hasBeenReset {
    margin-top: 50px;
  }

  @media (max-width: 900px) {
    width: 100vw;
    position: absolute;
    padding: 0;
  }

  h4 {
    color: #808080;
    font-weight: bold;
    font-size: 13px;
    margin-top: 2rem;

    span {
      color: #ff8d8d;
      cursor: pointer;
    }
  }
`;

export default Sidebar;
